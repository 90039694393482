type Mix = [number, number, number, number];

const WHITE_TOP = 35;
const BLUE_BOTTOM = 15;
const BLUE_TOP = 70;
const GREEN_BOTTOM = 40;
const GREEN_TOP = 85;
const RED_BOTTOM = 75;

function calcWhiteBlue(n: number): Mix {
	const top = WHITE_TOP;
	const bottom = BLUE_BOTTOM;
	if (n > top) return [0, 0, 0, 0];
	if (n < bottom) return [0, 0, 0, 100];
	const percent = (n - bottom) / (top - bottom);
	const b = Math.round(percent * 100);
	const w = Math.round((1 - percent) * 100);
	return [0, 0, b, w];
}
function calcGreenRed(n: number): Mix {
	const top = GREEN_TOP;
	const bottom = RED_BOTTOM;
	if (n > top) return [100, 0, 0, 0];
	if (n < bottom) return [0, 0, 0, 0];
	const percent = (n - bottom) / (top - bottom);
	const r = Math.round(percent * 100);
	const g = Math.round((1 - percent) * 100);
	return [r, g, 0, 0];
}

function calcBlueGreen(n: number): Mix {
	const top = BLUE_TOP;
	const bottom = GREEN_BOTTOM;
	if (n > top) return [100, 0, 0, 0];
	if (n < bottom) return [0, 0, 0, 0];
	const percent = (n - bottom) / (top - bottom);
	const g = Math.round(percent * 100);
	const b = Math.round((1 - percent) * 100);

	return [0, g, b, 0];
}

function calculateMixForTemp(temp: number): Mix {
	// For temps in the white and blue zones, calc gradient.
	if (temp <= WHITE_TOP && temp >= BLUE_BOTTOM) {
		return calcWhiteBlue(temp);
	}

	// For temps in the green and red zones, calc gradient.
	if (temp >= RED_BOTTOM && temp <= GREEN_TOP) {
		return calcGreenRed(temp);
	}

	// For temps in the blue and green zones, calc gradient.
	if (temp >= GREEN_BOTTOM && temp <= BLUE_TOP) {
		return calcBlueGreen(temp);
	}
	// For low temps, only show coldest
	if (temp <= BLUE_BOTTOM) {
		return [0, 0, 0, 100];
	}

	// For high temps, only show red
	if (temp >= GREEN_TOP) {
		return [100, 0, 0, 0];
	}

	// For temps that only fall in blue zone, show blue
	if (temp >= WHITE_TOP && temp <= GREEN_BOTTOM) {
		return [0, 0, 100, 0];
	}

	// For temps that only fall in the green zone, show green
	if (temp > BLUE_TOP && temp < RED_BOTTOM) {
		return [0, 100, 0, 0];
	}

	//default to all
	return [100, 100, 100, 100];
}

function generateTemps(): Mix[] {
	const tarr = Array.from(Array(100)).map((_, i) => i);
	return tarr.map(calculateMixForTemp);
}

export function calcTemp(temp: number) {
	const temps = generateTemps();
	return temps[temp];
}
