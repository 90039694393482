import React from 'react';
import styled from 'styled-components';
import logo from './logo.png';
import './App.css';

// window.cheat = false
//
// const HEIGHTS = [96, 84, 74, 62, 52, 40, 28, 18]
// const ANS = [
// 	18, 28, 62, 18, 18, 18, 62, 84, 18, 96, 40, 96, 18, 96, 18, 74, 18, 74, 84,
// 	62, 96, 74, 18,
// ]
// const ANS0 = [
//   18, 32, 32, 18, 46, 52, 39, 46, 18, 46, 18, 60, 25, 32, 53, 39, 46, 32, 18,
//   25, 32, 25, 18,
// ]
const ANS2 = [
	18, 32, 32, 18, 46, 53, 39, 46, 18, 46, 18, 60, 25, 32, 53, 39, 46, 32, 18, 25, 32, 25, 18,
];

const genres = [18, 25, 32, 39, 46, 53, 60];

// 18, 25, 32, 39, 46, 53, 60
// 00, 01, 02, 03, 04, 05, 06
// const dict = [
// 	{ genre: 0, val: 18 },
// 	{ genre: 2, val: 32 },
// 	{ genre: 2, val: 32 },
// 	{ genre: 0, val: 18 },
// 	{ genre: 4, val: 46 },
// 	{ genre: 5, val: 53 },
// 	{ genre: 3, val: 39 },
// 	{ genre: 4, val: 46 },
// 	{ genre: 0, val: 18 },
// 	{ genre: 4, val: 46 },
// 	{ genre: 0, val: 18 },
// 	{ genre: 6, val: 60 },
// 	{ genre: 1, val: 25 },
// 	{ genre: 2, val: 32 },
// 	{ genre: 5, val: 53 },
// 	{ genre: 3, val: 39 },
// 	{ genre: 4, val: 46 },
// 	{ genre: 2, val: 32 },
// 	{ genre: 0, val: 18 },
// 	{ genre: 1, val: 25 },
// 	{ genre: 2, val: 32 },
// 	{ genre: 1, val: 25 },
// 	{ genre: 0, val: 18 },
// ];

// const puzzleAnswer = dict.map((d, i) => {
// 	const n = d.genre - dict[i + 1]?.genre || 0;
// 	let dumb = `${n}`;
// 	if (dumb[0] === '-') {
// 		return dumb.replace('-', '+');
// 	} else {
// 		dumb = '-' + dumb;
// 		return dumb;
// 	}
// });

const Container = styled.div`
	margin: 100px auto;
	width: 90%;
	text-align: center;
	backrgound-color: red;
	max-width: 1220px;
`;

const Bars = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 0 auto;
	width: 90%;
	maxwidth: 1022px;
	padding: 20px;
	background-color: #9dd9d2;
	box-shadow: 10px 10px 0px 1px #392f5a;
`;
const Button = styled.button`
	width: 50%;
	font-size: 20px;
	padding: 10px;
	background-color: #f4d06f;
	border: none;
	box-shadow: 5px 5px 0px 1px #392f5a;
	margin-top: 20px;
`;

const B = styled.div`
	width: 20px;
	max-width: 20px;
	&:focus {
		outline: 4px solid #392f5a;
	}
`;
const Bar = ({
	onKeyDown,
	genre,
	height,
	index,
	displayMode,
}: {
	onKeyDown: any;
	height: number;
	genre?: number;
	index: number;
	displayMode: boolean;
}) => {
	const divRef = React.useRef<HTMLDivElement>(null);
	React.useEffect(() => {
		divRef.current?.focus();
	}, []);

	return (
		<B
			style={{
				marginRight: '16px',
				maxWidth: '16px',
				minWidth: '16px',
				backgroundColor: '#ff8811',
				borderRadius: '6px',
				border: '2px solid #392f5a',
				height: height * 2 + 'px',
			}}
			tabIndex={index + 1}
			ref={divRef}
			onKeyDown={onKeyDown}
		>
			{displayMode ? '' : genre}
		</B>
	);
};

const Logo = styled.img`
	height: 200px;
	margin-right: 40px;
`;

const Error = styled.div`
	width: 50%;
	padding: 5px;
	margin: 10px auto;
	background-color: #ff8811;
	box-shadow: 5px 5px 0px 1px #392f5a;
`;
function reorder(code: Array<number>, direction: 'left' | 'right', index: number) {
	const newCode = [...code];
	if (direction === 'left') {
		if (index === 0) {
			return newCode;
		}
		const temp = newCode[index - 1];
		newCode[index - 1] = newCode[index];
		newCode[index] = temp;
	} else {
		if (index === code.length - 1) {
			return newCode;
		}
		const temp = newCode[index + 1];
		newCode[index + 1] = newCode[index];
		newCode[index] = temp;
	}
	return newCode;
}

function App() {
	const [code, setCode] = React.useState([...ANS2].sort());
	const [displayMode, setDisplayMode] = React.useState(false);
	const [error] = React.useState<string | null>(null);
	const [width, setWidth] = React.useState<number>(window.innerWidth);

	function handleWindowSizeChange() {
		setWidth(window.innerWidth);
	}
	React.useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		};
	}, []);
	const isMobile = width <= 1260;

	const handleKeyDown = (e: any, index: number) => {
		if (e.key === 'ArrowRight') {
			setCode(reorder(code, 'right', index));
			if (index < 22) {
				e.currentTarget.nextElementSibling.focus();
			}
		} else if (e.key === 'ArrowLeft') {
			setCode(reorder(code, 'left', index));
			if (index >= 0) {
				e.currentTarget.previousElementSibling.focus();
			}
		}
	};
	return isMobile ? (
		<h1 style={{ padding: '30vh 20px' }}>Please view this on a larger screen... :(</h1>
	) : (
		<Container>
			{error && (
				<Error>
					<h3>{error}</h3>
				</Error>
			)}
			<h2>To continue, re-order the bars</h2>
			<Bars>
				<Logo alt="spotify-logo" src={logo} />
				{code.map((d, i) => (
					<Bar
						key={i}
						displayMode={displayMode}
						height={d}
						onKeyDown={(e: any) => handleKeyDown(e, i)}
						index={i}
						genre={genres.indexOf(d)}
					/>
				))}
			</Bars>
			<Button onClick={() => setDisplayMode(!displayMode)}>
				{displayMode ? 'Show' : 'Clear'} numbers
			</Button>
			<div style={{ margin: '50px auto' }}>
				<p>Click on a bar to select it; use the left and right arrows keys to order it.</p>
				<p>The first bar is in the correct location.</p>
				<p>Clear the numbers before scaning.</p>
			</div>
		</Container>
	);
}

export default App;
