import { useEffect, useState } from 'react'
import { calcTemp } from './utils'
import './temp.css'

interface BoxProps {
  isCircle: boolean
  color: string | number | undefined
}

function generateColor(m: [number, number, number, number]) {
  if (m[3] > 0) {
    if (m[3] === 100) {
      const w = (255 / 100) * Math.floor(Math.random() * m[3])
      return [w / 2, w * 0.4, w, 0]
    }
    const showBlue = Math.random() * 100 < m[2] ? true : false
    if (showBlue) {
      return [
        0,
        0,
        Math.round((255 / 100) * Math.floor(Math.random() * m[2])),
        0,
      ]
    } else {
      const w = (255 / 100) * Math.floor(Math.random() * m[3])
      return [w / 2, w * 0.4, w, 0]
    }
  }
  return m.map((x) => Math.round((255 / 100) * Math.floor(Math.random() * x)))
}

function Box({ color, isCircle }: BoxProps) {
  return (
    <div
      className="box"
      style={{
        borderRadius: isCircle ? '10px' : '1px',
        height: '20px',
        width: '20px',
        background: color,
      }}
    />
  )
}

function Home() {
  const [temp, setTemp] = useState(74)
  const [windSpeedIndex] = useState(5)
  const [isCircle] = useState(false)
  const [loading, setLoading] = useState(true)
  console.log('looading', loading)
  const [error, setError] = useState('')
  console.log('error', error)
  const apiKey = '27a5c2ea14dd07e51014facabc9718de'

  useEffect(() => {
    const fetchTemperature = async () => {
      try {
        // First get location from IP
        const ipResponse = await fetch('https://ipapi.co/json/')
        const locationData = await ipResponse.json()

        // Then fetch weather data using the coordinates
        const weatherResponse = await fetch(
          `https://api.openweathermap.org/data/2.5/weather?lat=${locationData.latitude}&lon=${locationData.longitude}&appid=${apiKey}&units=imperial`,
        )

        if (!weatherResponse.ok) {
          throw new Error('Weather data fetch failed')
        }

        const weatherData = await weatherResponse.json()
        setTemp(Math.round(weatherData.main.temp))
        setLoading(false)
      } catch (err) {
        setError('Failed to fetch weather data')
        setLoading(false)
      }
    }

    fetchTemperature()
  }, [apiKey])

  const t = calcTemp(temp)
  const boxes = Array.from(Array(4)).map(() => Array.from(Array(32)))
  const boxel = document.querySelectorAll('.box')

  // Assign random duration to each box
  boxel.forEach((box) => {
    // Random duration between 2 and 5 seconds
    const randomDuration = Math.random() * 3 + windSpeedIndex + 's'
    ;(box as HTMLElement).style.setProperty('--duration', randomDuration)
  })

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', gap: '1px' }}>
          {boxes.map((_, i) => {
            return (
              <div
                key={i}
                style={{ display: 'flex', flexDirection: 'column', gap: '1px' }}
              >
                {boxes[i].map((_, j) => {
                  const g = generateColor(t)
                  return (
                    <Box
                      isCircle={isCircle}
                      color={`rgba(${g[0]}, ${g[1]}, ${g[2]}, 1)`}
                      key={j}
                    />
                  )
                })}
              </div>
            )
          })}
        </div>
      </div>
      <h1 style={{ color: 'white', marginLeft: '30px' }}>Hello!</h1>
    </div>
  )
}

export default Home
